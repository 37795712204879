@tailwind base;

@layer base {
  html {
    -webkit-font-smoothing: antialiased; /* remains from CssBaseline */
    -moz-osx-font-smoothing: grayscale; /* remains from CssBaseline */
  }

  body {
    font-family: theme('fontFamily.sans');
  }

  svg {
    vertical-align: initial; /* diffeerence in resets. tailwind resets to "middle" */
  }

  p {
    margin-bottom: 13px; /* difference in resets. tailwind resets to "0" */
  }

  ol {
    list-style: decimal; /* difference in resets. tailwind resets to "none" */
  }
  ul {
    list-style: initial; /* difference in resets. tailwind resets to "none" */
  }
}

@tailwind components;
@tailwind utilities;

@layer components {
  .hdot::after {
    content: '.';
    color: theme('colors.primary.base');
  }
}

/* mui v4 default. default (100%) causes modals to scroll out of viewport if modal is taller than viewport */
.MuiDialog-scrollBody::after {
  height: unset !important;
}

.MuiCheckbox-root {
  @apply text-primary-base;
}

.icon-5 {
  @apply h-5 w-5 flex;
}

.icon-5 > svg {
  @apply text-xl;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
}